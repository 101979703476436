<template>
    <div>
        <v-container>
            <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Control acceso externo
            </div>
            
            <v-expansion-panels v-model="activePanels">
                <v-expansion-panel style="background-color: #F5F5F5" @click.prevent="toogleFilters">
                    <v-expansion-panel-header>
                        <span class="header">Filtros</span>
                        <template v-slot:actions>
                            <v-btn icon @click.stop="toogleFilters">
                                <v-icon>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form :disabled="loading">
                            <v-row>
                                <v-col cols="12" sm="6" md="5" lg="4" xl="3">
                                    <v-text-field
                                        class="mb-n7" outlined dense text required
                                        v-model="filtros.Tarjeta" label="Tarjeta"
                                        @change="loadData"
                                    ></v-text-field>
                                </v-col>
                                <v-col v-if="checkCard" cols="12" sm="6" md="5" lg="4" xl="3">
                                    <v-progress-circular
                                        v-if="loading"
                                        indeterminate color="primary"
                                    ></v-progress-circular>
                                    <div v-else-if="totalDataRows > 0">
                                        <v-alert class="mb-0" icon="mdi-check-bold" width="260" dense outlined type="success">
                                            Colaborador Homologado
                                        </v-alert>
                                    </div>
                                    <div v-else>
                                        <v-alert class="mb-0" icon="mdi-close-thick" width="200" dense outlined type="error">
                                            NO Homologado
                                        </v-alert>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions: [15, 30, 50, 100],disablePagination:loading, disableItemsPerPage: loading}"
                :server-items-length="totalDataRows"
                :loading="loading"
                @click:row="onBtnShowRegisterClick"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1 mt-4"
                dense
                >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.FechaDesde="{ item }">
                    {{ formatDate(item.FechaDesde) }}
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.FechaHasta="{ item }">
                    {{ formatDate(item.FechaHasta) }}
                </template>
            </v-data-table>
        </v-container>

        <!-- DIALOGO DE REGISTRO -->
        <v-dialog
            v-model="showRegisterDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    REGISTRAR
                </v-card-title>

                <v-card-text class="mt-2">
                    <v-form ref="form" autocomplete="off">
              
                        <!-- FORMULARIO ACCESO -->
                        <v-row>
                            <v-col v-if="accesoSelected != null" class="px-7 mt-4" cols="12">
                                <dl>
                                    <dt class="mb-1">
                                        <v-icon class="mr-1">mdi-card-account-details</v-icon>
                                        <b>Tarjeta: </b>{{accesoSelected.Tarjeta}}
                                    </dt>
                                    <dt class="mb-1">
                                        <v-icon class="mr-1">mdi-account</v-icon>
                                        <b>Nombre: </b>{{accesoSelected.Nombre}}
                                    </dt>
                                    <dt class="mb-1">
                                        <v-icon class="mr-1">mdi-domain</v-icon>
                                        <b>Compañia: </b>{{accesoSelected.Compania}}
                                    </dt>
                                    <dt class="mb-1">
                                        <v-icon class="mr-1">mdi-cog</v-icon>
                                        <b>Motivo: </b>{{accesoSelected.Motivo}}
                                    </dt>
                                </dl>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="px-7" cols="12">
                                <v-textarea
                                    class="mt-0 mb-n7" outlined auto-grow
                                    v-model="acceso.Observaciones"
                                    label="Observaciones">
                                </v-textarea>
                            </v-col>
                            <v-col class="px-7" cols="12">
                                <v-radio-group v-model="acceso.Tipo" row>
                                    <v-radio v-for="opt in tipoOptions" :key="opt.value"
                                        :label="opt.label" :value="opt.value"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            :loading="loading"
                            @click="onBtnSave"
                            color="primary"
                            class="mt-2 mb-4 mr-2"
                            style="width:100px">
                            Registrar
                        </v-btn>
                        
                        <v-btn
                            class="mt-2 mb-4 mr-2"
                            style="width:100px"
                            @click="onBtnCancelRegisterClick">
                            Cancelar
                        </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import funcs from "../../funcs";
import { mapState } from "vuex";
export default ({
    components: {},
    computed: {
        ...mapState(['empId', 'dirId', 'perId', 'perIdAux', 'urlRaiz', 'esTienda'])
    },
    data () {
        return {
            loading: false,
            activePanels: 0,
            showRegisterDialog: false,
            checkCard: false,
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
                { text: 'Nombre', width:200, sortable: false, value: 'Nombre' },
                { text: 'Tarjeta', width:100, sortable: false, value: 'Tarjeta' },
                { text: 'Compañia', width:200, sortable: false, value: 'Compania' },
                { text: 'Fecha desde', width: 50, sortable: false, value: 'FechaDesde' },
                { text: 'Fecha hasta', width: 50, sortable: false, value: 'FechaHasta' }
            ],
            dtOptionsLoaded: false,
            acceso: {
                AccesoExternoId: 0,
                Tipo: 0
            },
            tipoOptions: [
                { label: 'ENTRADA', value: 0 },
                { label: 'SALIDA', value: 1 }
            ],
            filtros: {
                Tarjeta: ''
            },
            accesoSelected: null
        }
    },
    watch: {
        options: {
            handler () {
            if(!this.dtOptionsLoaded){
                this.dtOptionsLoaded = true;
                return;
            }
            this.loadData();
            },
            deep: true
        },
        // acceso: {
        // handler () {
        //     this.validate();
        // },
        // deep: true,
        // }
    },
    mounted() {
        if (!this.esTienda) { this.$router.push('/'); return; }
        this.loadData();
    },
    methods: {
        goToList() {
            this.$router.push('/');
        },

        //#region Llamadas a la API
        getDataFromApi() {
            this.loading = true;

            var NumRegsPag = this.options.itemsPerPage;
            var NumPag = this.options.page;

            return new Promise((resolve) => {
            const controllerParameters = {
                NumRegsPag: NumRegsPag,
                NumPag: NumPag,
                Fecha: new Date(),
                DirId: this.dirId,
                Tarjeta: this.filtros.Tarjeta
            };       
            const AuthToken = localStorage.getItem('token');
            axios({ method: "POST", "url": this.urlRaiz + "/api/acceso-externo/list", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
            .then(result => {
                const items = result.data.EntsList;
                const total = result.data.EntsCount;

                setTimeout(() => {
                    this.loading = false
                    resolve({
                        items,
                        total
                    })
                }, 1000)
            })
            .catch(error => {
                if(error != null) {
                    this.loading = false;
                    alert("Error al cargar los accesos");
                }
            });
            })
        },
        postDataToApi() {
            this.loading = true;

            return new Promise((resolve) => {
            const controllerParameters = {
                // AccesoExternoId: this.acceso.AccesoExternoId,
                DirId: this.dirId,
                PerId: this.perIdAux,
                Acceso: this.acceso
            };
            const AuthToken = localStorage.getItem('token');
            axios({ method: "POST", "url": this.urlRaiz + "/api/acceso-externo/register", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
            .then(result => {
                const item = result.data.Acceso;

                setTimeout(() => {
                    this.loading = false;
                    resolve({
                        item
                    })
                }, 1000)
            })
            .catch(error => {
                if(error != null) {
                    this.loading = false;
                    alert('Error al guardar el acceso.');
                }
            });
            })
        },
        //#endregion

        //#region Carga de datos
        loadData() {
            this.checkCard = (this.filtros.Tarjeta != null && this.filtros.Tarjeta != '');
            this.getDataFromApi()
            .then(data => {
                this.dataRows = data.items
                this.totalDataRows = data.total
            });
        },
        //#endregion

        //#region Modal
        onBtnSave() {
            // this.validate();
            // if(!this.isValid) { return; }
            this.postDataToApi().then(data => {
                if(data.item.AccesoExternoDetalleId != null) {
                    alert("Registro correcto");
                    this.onBtnCancelRegisterClick();
                }
                else {
                    alert('Error al guardar el acceso.');
                }
            });
        },
        onBtnShowRegisterClick(item) {
            this.accesoSelected = item;
            this.acceso.AccesoExternoId = item.AccesoExternoId;
            this.showRegisterDialog = true;
        },
        onBtnCancelRegisterClick() {
            this.showRegisterDialog = false;
            this.accesoSelected = null;
            this.acceso = {
                AccesoExternoId: 0,
                Tipo: 0
            };
        },
        //#endregion
        
        formatDate(value) {
            return funcs.formatDate(value);
        },
        toogleFilters() {
            this.activePanels = this.activePanels == null ? 0 : undefined;
        },
    } 
})
</script>
